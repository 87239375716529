import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import Filter from "../../../components/reports/survey/Filter";
import List from "../../../components/reports/survey/List";
import { AuthContext } from "../../../context/auth";
import api from "../../../services/api";
import { Translate } from "../../../translate";
import { findPath } from "../../../util/location";
import { messageError } from "../../../util/messages";
import { number } from "../../../util/number";
import { datetimeSort, toDateLocalNow, toDateLocalToday } from "../../../util/time";

const Survey = () => {
  const { environment } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [range, setRange] = useState([
    toDateLocalToday(),
    toDateLocalNow(),
  ]);

  const search = async (values) => {
    setLoading(true);
    let event = await fetchEvents(values);
    let survey = await fetchSurvey(values);
    event.forEach((element) => {
      element.range = datetimeSort(range[0]) + "-" + datetimeSort(range[1]);
      let item = survey.find(
        (i) => i.id === element.locationId && i.date === element.date
      );
      let location = findPath(locations, element.locationId);
      element.location = location ? location : element.location;
      element.entry = element.value;
      if (item) {
        element.avaliation = item ? item.value : 0;
        element.percentualAvaliation =
          number((element.avaliation / element.entry) * 100, 1) + " %";
        element.grades = item.grades;
      }
    });
    setData(event);
    setLoading(false);
  };

  const fetchEvents = async (values) => {
    try {
      let { data } = await api.get("/control/event/group/local/entry", {
        params: values,
      });
      return data;
    } catch (error) {
      messageError(error);
    }
  };

  const fetchSurvey = async (values) => {
    try {
      let { data } = await api.get("/survey/survey/group/local/grade", {
        params: values,
      });
      return data;
    } catch (error) {
      messageError(error);
    }
  };

  const fetchLocations = () => {
    api
      .get("/maintenance/location/tree/", { params: { disabled: true } })
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => messageError(error));
  };

  useEffect(() => {
    fetchLocations();
  }, [environment]);

  return (
    <>
      <Row>
        <Col xs={24}>
          <span className="text-title primary-text-color">
            {Translate.t("menu.survey")}
          </span>
        </Col>
        <Col xs={24} style={{ marginTop: "10px" }}>
          <span className="text-sub secondary-text-color">
            {Translate.t("title.info.survey")}
          </span>
        </Col>
      </Row>
      <Filter
        search={search}
        loading={loading}
        data={data}
        range={range}
        setRange={setRange}
      />
      <List search={search} data={data} loading={loading} range={range} />
    </>
  );
};

export default Survey;
