import dayjs from "dayjs"
import { Translate } from "../translate"
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
var tz = dayjs.tz.guess()

export const datetimeFormat = () => {
    return Translate.language.includes("pt") ? "DD/MM/YYYY HH:mm:ss" : "YYYY-MM-DD HH:mm:ss"
}

export const datetimeFormatSort = () => {
    return Translate.language.includes("pt") ? "DD/M/YY HH:mm" : "YY-M-DD HH:mm"
}


export const dateFormat = () => {
    return Translate.language.includes("pt") ? "DD/MM/YYYY" : "YYYY-MM-DD"
}

export const time = (args) => {
    if (args)
        return dayjs(args).utc('z').tz(tz).local().format("HH:mm:ss")
    return dayjs().local().format("HH:mm:ss")
}

export const date = (args) => {
    return dayjs(args).utc('z').tz(tz).local().format(dateFormat())
}

export const datetime = (args) => {
    return dayjs.utc(args).utc('z').tz(tz).local().format(datetimeFormat())
}

export const datetimeSort = (args) => {
    return dayjs.utc(args).utc('z').tz(tz).local().format(datetimeFormatSort())
}


export const toDateLocal = (args) => {
    return dayjs(args).utc('z').tz(tz).local()
}

export const toDateUtc = (args) => {
    return dayjs(args).utc()
}

export const toDateLocalNow = () => {
    return dayjs().local()
}

export const toDateLocalToday = () => {
    return dayjs().hour(0).minute(0).second(0).millisecond(0)
}

export const toDateLocalChart = (args) => {
    return dayjs(args).utc('z').tz(tz).local().format("YYYY-MM-DDTHH:mm:ss")
}


export const secondToTimeString = (args) => {
    let dateObj = new Date(args * 1000);
    let hours = dateObj.getUTCHours();
    let minutes = dateObj.getUTCMinutes();
    let seconds = dateObj.getSeconds();

    return hours.toString().padStart(2, '0') + ':' +
        minutes.toString().padStart(2, '0') + ':' +
        seconds.toString().padStart(2, '0');
}



