import Keycloak from 'keycloak-js';

export const keycloak = new Keycloak({
    "realm": process.env.REACT_APP_KEYCLOAK_REALM,
    "url": process.env.REACT_APP_KEYCLOAK_URL,
    "clientId": process.env.REACT_APP_KEYCLOAK_CLIENT
})

export const keycloakProviderInitConfig = {
    onLoad: 'login-required'
    //onLoad: 'check-sso'
}



