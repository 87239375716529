import { DownOutlined } from "@ant-design/icons";
import { Checkbox, Col, Popover, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { Translate } from "../../../translate";

const List = ({ data, pagination, loading, search, categories }) => {
  const [columnsVisives, setColumnsVisives] = useState([]);
  const [columns, setColumns] = useState([]);
  const handleCategories = (cat, categories) => {
    let item =
      categories &&
      categories.length > 0 &&
      categories.find((i) => i.name === cat);
    return item ? item.value : 0;
  };

  const handleColumns = () => {
    setColumns([
      {
        index: 0,
        title: Translate.t("common.period.title"),
        dataIndex: "range",
        key: "range",
        width: 250,
      },
      {
        index: 1,
        title: Translate.t("aggregation.title"),
        dataIndex: "date",
        key: "date",
        width: 150,
      },
      {
        index: 2,
        title: Translate.t("location.title"),
        dataIndex: "location",
        key: "location",
        width: 450,
        render: (text, record) => record.location + " - " + record.father,
      },
      {
        index: 3,
        title: Translate.t("dashboard.avg"),
        dataIndex: "value",
        key: "value",
        width: 200,
      },
        ...categories.map((category, index) => ({
          index: (index = 4),
          title: category.label,
          dataIndex: category.label,
          key: category.label,
          render: (text, record) =>
            handleCategories(category.label, record.categories),
          width: 200,
        })),
      ,
    ]);
  };

  useEffect(() => {
    handleColumns();
  }, [categories]);

  return (
    <>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={24} className="card_default padding-list">
          <Row className="space-botton padding">
            <Col xs={12}>
              <span className="text-header primary-text-color">
                {Translate.t("title.search_result")}
              </span>
            </Col>
            <Col xs={12} className="alng-rigth">
              {columnsVisives.length > 0 && (
                <Popover
                  content={(columnsVisives || []).map((i, index) => (
                    <p value={i.label}>
                      <Checkbox
                        checked={i.visible}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {" "}
                        <span className="secondary-text-color">{i.label}</span>
                      </Checkbox>
                    </p>
                  ))}
                  placement="bottom"
                  title={null}
                  trigger="hover"
                >
                  <span className="secondary-text-color">
                    {" "}
                    {Translate.t("common.selectInfo")}
                  </span>
                  <DownOutlined />
                </Popover>
              )}
            </Col>
          </Row>
          <Table
            scroll={{ y: 99999, x: 400 }}
            size="small"
            dataSource={data}
            columns={columns}
            loading={loading}
            onRow={(record) => ({
              onClick: () => {
                let data = {
                  range: record.range,
                  categories: record.categories.map((i) => i.name).join(","),
                  location: record.location,
                }
                let newUrl = "/maintenance?" + new URLSearchParams(data);
                window.open(newUrl, '_blank');
              },
            })}
          />
        </Col>
      </Row>
    </>
  );
};

export default List;
