const languages = {
    pt: {
        translations: {
            title: {
                search: "Filtros",
                search_result: "Resultados da pesquisa",
                search_header: "Escreva para procurar documentos, funcionários, etc.",
                info: {
                    camera: "Informação geral sobre as sensores",
                    survey: "Relatório de avaliações",
                    local: "Informação geral sobre os sítios",
                    rfid: "Informação geral sobre os RFIDs",
                    maintenance: "Informação geral sobre as acções corretivas",
                    maintenance_category: "Informação geral sobre as categorias de manutenção",
                    device: "Informação geral sobre os dispositivos",
                    dashboard: "Toda a informação básica neste ecrã",
                    statistics: "Informação geral sobre as Estatística",
                    hosthttp: "Informação geral sobre os servidores de alarme",
                    maintenance_report: "Relatório de acções corretivas",
                    visits: "Relatório de visitas",
                    alert: "Relatório de alertas",
                }
            },
            common: {
                profile:"Minha conta",
                public: "Exibir para usuários",
                add: "Adicionar {{name}}",
                action: "Ação",
                count: "Total",
                icon: "Imagem",
                iconChange: "Alterar imagem",
                iconBackground: "A imagem esta sobre um fundo transparente",
                upload: "Upload",
                enabled: "Ativo",
                deleted: "Removido",
                all: "Todos",
                back: "Voltar",
                creationDate: "Data de criação",
                lastConnection: "Última conexão",
                selectInfo: "Selecione a informação",
                selectAction: "Selecione a ação",
                selectWarn: "Selecione uma informação na tabela",
                start: {
                    title: "Início",
                    placeholder: "Introduza aqui o início",
                    select: "Selecione o início",
                    required: "Início é obrigatório",
                },
                end: {
                    title: "Fim",
                    placeholder: "Introduza aqui o fim",
                    select: "Selecione o fim",
                    required: "Fim é obrigatório",
                },
                id: {
                    title: "Id",
                    placeholder: "Introduza aqui o id",
                    select: "Selecione o id",
                    required: "Id é obrigatório",
                },
                date: {
                    title: "Data",
                    placeholder: "Introduza aqui a data",
                    select: "Selecione a data",
                    required: "Data é obrigatório",
                },
                username: {
                    title: "Username",
                    placeholder: "Introduza aqui o username",
                    select: "Selecione o username",
                    required: "Username é obrigatório",
                },
                name: {
                    title: "Nome",
                    placeholder: "Introduza aqui o nome",
                    select: "Selecione o nome",
                    required: "Nome é obrigatório",
                },
                code: {
                    title: "Código",
                    placeholder: "Introduza aqui o código",
                    select: "Selecione o código",
                    required: "Código é obrigatório",
                },
                serialNumber: {
                    title: "Número serial",
                    placeholder: "Introduza aqui o número serial",
                    select: "Selecione o número serial",
                    required: "Número serial é obrigatório",
                },
                password: {
                    title: "Palavra passe",
                    placeholder: "Introduza a palavra passe",
                    required: "Palavra passe é obrigatório",
                },
                status: {
                    title: "Estado",
                    placeholder: "Introduza aqui o estado",
                    select: "Selecione o estado",
                    required: "Estado é obrigatório",
                },
                location: {
                    title: "Sítio",
                    placeholder: "Introduza o sítio",
                    select: "Selecione o sítio",
                    required: "Sítio é obrigatório",
                },
                distance: {
                    title: "Distância",
                    placeholder: "Introduza a distância em metros",
                    select: "Selecione a Distância",
                    required: "Distância é obrigatório",
                },
                direction: {
                    title: "Direção",
                    placeholder: "Introduza a direção",
                    select: "Selecione o direção",
                    required: "Direção é obrigatório",
                },
                category: {
                    title: "Categorias",
                    placeholder: "Introduza aqui a categoria",
                    select: "Selecione a categoria",
                    required: "Categoria é obrigatório",
                    amount: {
                        placeholder:"Introduza aqui a quantidade",
                        required:"Quantidade de categorias é obrigatório",
                    },
                },
                father: {
                    title: "Pai",
                    placeholder: "Introduza aqui o pai",
                    select: "Selecione o pai",
                    required: "Pai é obrigatório",
                },
                rfid: {
                    title: "RFID",
                    placeholder: "Introduza aqui o RFID",
                    select: "Selecione o RFID",
                    required: "RFID é obrigatório",
                },
                type: {
                    title: "Tipo",
                    placeholder: "Introduza aqui o tipo",
                    select: "Selecione o tipo",
                    required: "tipo é obrigatório",
                },
                ip: {
                    title: "Ip",
                    placeholder: "Introduza aqui o ip",
                    select: "Selecione o ip",
                    required: "Ip é obrigatório",
                },
                mac: {
                    title: "MAC",
                    placeholder: "Introduza aqui o MAC",
                    select: "Selecione o MAC",
                    required: "MAC é obrigatório",
                },
                host: {
                    title: "Host",
                    placeholder: "Introduza aqui o endereço do host",
                    select: "Selecione o Host",
                    required: "Host é obrigatório",
                },
                protocol: {
                    title: "Protocolo",
                    placeholder: "Introduza aqui o Protocolo",
                    select: "Selecione o Protocolo",
                    required: "Protocolo é obrigatório",
                },
                port: {
                    title: "Porta",
                    placeholder: "Introduza aqui o Porta",
                    select: "Selecione o Porta",
                    required: "Porta é obrigatório",
                },
                token: {
                    title: "Token",
                    placeholder: "Introduza aqui o token de acesso",
                    select: "Selecione o Token",
                    required: "Token é obrigatório",
                },
                url: {
                    title: "Url",
                    placeholder: "Introduza aqui a url",
                    select: "Selecione a url",
                    required: "Url é obrigatório",
                },
                path: {
                    title: "Path",
                    placeholder: "Introduza aqui o path",
                    select: "Selecione a path",
                    required: "Path é obrigatório",
                },
                period: {
                    title: "Período",
                    placeholder: "Introduza aqui o período",
                    select: "Selecione a período",
                    required: "Período é obrigatório",
                  },
            },
            menu: {
                dashboard: "Painel",
                panel: "Painel",
                camera: "Sensores",
                survey: "Avaliações",
                category: "Categorias",
                rfid: "RFIDs",
                locations: "Sítios",
                maintenance: "Ações corretivas",
                device: "Dispositivos",
                statistics: "Estatística",
                hosthttp: "Servidor de alarme",
                visits: "Visitas",
                alert: "Alertas",
            },
            dashboard: {
                chart_users: "Gráfico que mostra número de utilizadores x avaliação",
                chart_problems: "Taxa de reclamações por tipo de avaria/problema",
                users: "Utilizadores",
                surveys: "Avaliações",
                occupation_time: "Ocupação por hora",
                maintenance: "Ações corretivas em tempo real",
                maintenance_category: "Ações corretivas por categorias",
                maintenance_location: "Ações corretivas por local",
                maintenance_rfid: "Ações corretivas por rfid",
                entrys: "Entradas",
                exits: "Saídas",
                men: "Homem",
                woman: "Mulher",
                all: "Todos",
                last_month: "Último 30 dias",
                seven_day: "Último 7 dias",
                yesterday: "Ontem",
                today: "Hoje",
                avg: "Média do tempo de resposta",
                notification: "Notificações",
                top4: "Top 4 - WCs mais avaliados",
                avgwc: "Média dos WCs *",
                loadmore: "Carregar mais",
                minute: "Há {{minute}} minutos",
            },
            action: {
                close: "Fechar",
                view: "ver",
                title: "Ações",
                new: "Nova(o) {{name}}",
                save: "Salvar",
                edit: "Editar {{name}}",
                delete: "Remover",
                clone: "Clonar {{name}}",
                copy: "Copiar {{name}}",
                paste: "Colar {{name}}",
                logout: "Sair",
                search: "Pesquisar",
                clear: "Limpar",
                cancel: "Cancelar",
                ok: "Ok",
                yes: "Sim",
                no: "Não",
                xlsx: "Exportar para Xlsx",
                csv: "Exportar para Csv",
                export: "Exportar",
                download: "Baixar relatório",
                go: "Ir para {{name}}",
            },
            message: {
                confirm: "Confirmação",
                confirmContentDelete: "Você realmente deseja remover: {{name}}?",
                confirmContentAuth: "Você realmente deseja autorizar {{name}}?",
                confirmContentBlock: "Você realmente quer bloquear {{name}}?",
                succes: "Operação realizada com sucesso",
                save: "{{name}} salva(a)",
                update: "{{name}} atualizada(o)",
                remove: "{{name}} removida(o)"
            },
            error: {
                title: "Erro",
                operation: "Erro ao executar esta operação",
                down: "Sem conexão com o sistema",
                notauthorized: "Tu não tem permissão para aceder esta página.",
                tryagain: "Tentar novamente",
                env: "Sem ambiente"
            },
            device: {
                title: "Dispositivo",
                key: {
                    title: "Chave publica",
                    placeholder: "Introduza aqui a chave publica",
                    select: "Selecione a chave publica",
                    required: "Chave publica é obrigatório",
                },
                deviceId: {
                    title: "ID de dispositivo",
                    placeholder: "Introduza aqui o ID de dispositivo",
                    select: "Selecione o ID de dispositivo",
                    required: "ID de dispositivo é obrigatório",
                },
                qrcode: "Ler código QR",
                android: "Android",
                brand: {
                    title: "Marca",
                    placeholder: "Introduza aqui a marca",
                    select: "Selecione a marca",
                    required: "Marca é obrigatório",
                },
                authorize: "Autorizar",
                block: "Bloquear",
                blocked: "Bloqueado",
                authorized: "Autorizado",
                qrcodeinfo: "No app acesse: Configuração da aplicação > segurança e posicione o qrcode na frente da câmara",
            },
            camera: {
                title: "Sensor",
                select: "Selecione a sensor",
                model: {
                    title: "Modelo",
                    placeholder: "Introduza aqui o modelo",
                    select: "Selecione o modelo",
                    required: "Modelo é obrigatório",
                },
                config: "Configuração",
            },
            hosthttp: {
                title: "Servidor de alarme",
                select: "Selecione o servidor de alarme",
            },
            location: {
                generalData: "Dados gerais",
                children: "Infantil",
                feminine: "Feminino",
                masculine: "Masculino",
                deficient: "Deficiente",
                nursery: "Berçario",
                block: "Bloco",
                floor: "Piso",
                bathroom: "Casa de banho",
                title: "Local",
                titles: "Locais",
                empty: "Selecione um sítio ou clique em Novo sítio para começar",
                father: "Pai",
                fatherMe: "Não pode ser o próprio pai",
                suggestionMe: "Não é possível sugerir este mesmo sítio",
                up: "Acima",
                down: "Baixo",
                left: "Esquerda",
                right: "Direita",
                suggested: "Sítios sugeridos",
                capacity: {
                    title: "Capacidade",
                    placeholder: "Introduza aqui capacidade",
                    select: "Selecione a capacidade",
                    required: "Capacidade é obrigatório",
                },
                audience: {
                    title: "Público",
                    placeholder: "Introduza aqui o público",
                    select: "Selecione o público",
                    required: "Público é obrigatório",
                }
            },
            rfid: {
                title: "RFID",
                father: "pai",
            },
            maintenance: {
                maintenance: "Acções corretivas de equipamentos",
                cleaning: "Limpeza/Repor",
                title: "Acções corretivas",
                type:{
                    title:"Tipo",
                    placeholder:"Introduza aqui o tipo",
                    select:"Selecione o tipo",
                    required:"Tipo é obrigatório",
                },
                histories: {
                    title: "Históricos",
                    placeholder: "Introduza aqui o histórico",
                    select: "Selecione o histórico",
                    required: "Histórico é obrigatório",
                },
                start: {
                    title: "Início manutenção",
                    placeholder: "Introduza o início manutenção",
                    select: "Selecione o início manutenção",
                    required: "Início manutenção é obrigatório",
                },
                end: {
                    title: "Fim manutenção",
                    placeholder: "Introduza o fim manutenção",
                    select: "Selecione o fim manutenção",
                    required: "Fim manutenção é obrigatório",
                },
                status: {
                    started: "Iniciado",
                    canceled: "Cancelado",
                    finished: "Finalizado",
                    pending: "Pendente"
                }
            },
            survey: {
                title: "Avaliação",
                titles: "Avaliações",
                grades: {
                    title: "Notas",
                    placeholder: "Introduza aqui o nota",
                    select: "Selecione a nota",
                    required: "nota é obrigatório",
                    bad: "Ruim",
                    regular: "Regular",
                    good: "Bom",
                    excellent: "Óptima",
                }
            },
            event: {
                entry: "Entrada",
                exit: "Saída",
                adjust: "Ajuste",
            },
            category: {
                notify: {
                    title: "Notificar após quantos utilizadores",
                    placeholder: "Introduza aqui o número de utilizadores",
                    select: "Selecione número de utilizadores",
                    required: "Notificar é obrigatório",
                },
                nameTranslated: "Nome segundo idioma",
            },
            report: {
                percentualAvaliation: "% Ava.",
                avaliation: "Avaliações",
                visits: "Visitas",
              },
              aggregation: {
                title: "Agregação",
                select: "Selecione uma agregação",
                HOUR: "Hora",
                DAY: "Dia",
                MONTH: "Mês",
                WEEK: "Semana",
                YEAR: "Ano",
              },
        }
    }
}
export { languages }