import { DeleteOutlined, DownOutlined, EditOutlined, ExclamationCircleFilled } from "@ant-design/icons"
import { Button, Checkbox, Col, Modal, Popover, Row, Table } from "antd"
import React, { useEffect, useRef, useState } from "react"
import api from "../../services/api"
import { Translate } from "../../translate"
import { getValueStorage, setValueStorage } from "../../util/localStorange"
import { messageError, messageWarn, notification } from "../../util/messages"
import { datetime } from "../../util/time"
import Register from "./Register"

const List = ({ data, pagination, loading, search, locations, item, setItem, open, setOpen , hosts}) => {

    const [columnsVisives, setColumnsVisives] = useState([])
    const [contextMenuPosition, setContextMenuPosition] = useState(null)
    const [contextMenuVisible, setContextMenuVisible] = useState(false)
    const divMenuContext = useRef(null)

    const handleContextMenu = (event, record) => {
        const container = document.getElementById("container-table")
        const containerLeft = container.offsetLeft
        const containerTop = container.offsetTop
        event.preventDefault()
        setItem(record)
        setContextMenuPosition({ x: event.clientX - containerLeft, y: event.clientY - containerTop })
        setContextMenuVisible(true)
    }

    const handleOnClick = (event, record) => {
        event.preventDefault()
        if (!contextMenuVisible) {
            handleEdit(record)
        }
    }

    const handleTableChange = (page, filters, sorter) => {
        search({
            page: page.current - 1,
            rowPerPage: page.pageSize
        })
    }

    const handleRemove = async () => {
        try {
            if (item && item.id) {
                let { data } = await api.delete(`/control/camera/${item.id}`)
                if (data) {
                    notification('success', 'remove', Translate.t("camera.title"))
                    handleTableChange(pagination)
                }
            } else
                messageWarn(Translate.t("common.selectWarn"))
        }
        catch (error) {
            messageError(error)
        }
    }

    const handleEdit = async (_item) => {
        try {
            if (_item && _item.id) {
                let { data } = await api.get(`/control/camera/${_item.id}`)
                setItem(data)
                setOpen(true)
            } else
                messageWarn(Translate.t("common.selectWarn"))

        } catch (error) {
            messageError(error)
        }
    }

    const handelPasswordHover = (event) => {
        let span = event.target
        span.innerText = span.dataset.value
    }
    const handelPasswordOut = (event) => {
        let span = event.target
        span.innerText = "*****"
    }

    const handelPassword = (value) => {
        return <span data-value={value} onMouseOut={(event) => handelPasswordOut(event)} onMouseOver={(event) => handelPasswordHover(event)}>****</span>
    }

    const columns = [
        {
            index: 0,
            title: Translate.t("common.name.title"),
            dataIndex: 'name',
            key: 'name',
            width: 250
        },
        {
            index: 1,
            title: Translate.t("common.mac.title"),
            dataIndex: 'mac',
            key: 'mac',
            width: 250
        },
        {
            index: 2,
            title: Translate.t("common.serialNumber.title"),
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            width: 400
        },
        {
            index: 3,
            title: Translate.t("common.ip.title"),
            dataIndex: 'ip',
            key: 'ip',
            width: 250
        },
        {
            index: 4,
            title: Translate.t("common.location.title"),
            dataIndex: ["location", "name"],
            key: 'location.name',
            width: 250,
            render: (text, record) => <span>{(record.location.father ? record.location.father.name + " - " : "") + record.location.name}</span>
        },
        {
            index: 5,
            title: Translate.t("common.lastConnection"),
            dataIndex: 'lastCommunication',
            key: 'lastCommunication',
            width: 250,
            render: (text, record) => text ? datetime(text) : ""
        },
        {
            index: 6,
            title: Translate.t("common.username.title"),
            dataIndex: 'userName',
            key: 'userName',
            width: 250
        },
        {
            index: 7,
            title: Translate.t("common.password.title"),
            dataIndex: 'password',
            key: 'password',
            width: 250,
            render: (text, record) => handelPassword(text)
        },
        {
            index: 8,
            title: Translate.t("common.enabled"),
            dataIndex: 'enabled',
            key: 'enabled',
            width: 250,
            render: (text, record) => text ? Translate.t("action.yes") : Translate.t("action.no")
        },
        {
            index: 9,
            title: Translate.t("camera.model.title"),
            dataIndex: 'model',
            key: 'model',
            width: 250
        },
        {
            index: 10,
            title: Translate.t("common.creationDate"),
            dataIndex: 'creationDate',
            key: 'creationDate',
            width: 250,
            render: (text, record) => text ? datetime(text) : ""
        },
        {
            index: 11,
            title: Translate.t("common.id.title"),
            dataIndex: 'id',
            key: 'id',
            width: 250
        }
    ]

    const items = [
        {
            key: '1',
            item: (
                <Button icon={<EditOutlined />} key="edit" disabled={!item} title={Translate.t("action.edit", { name: "" })} onClick={() => {
                    setContextMenuVisible(false)
                    handleEdit(item)
                }}>
                    {Translate.t("action.edit", { name: "" })}
                </Button>
            ),

        },
        {
            key: '2',
            item: (
                <Button key="remove" icon={<DeleteOutlined />} disabled={!item} title={Translate.t("action.delete")}
                    onClick={() => {
                        setContextMenuVisible(false)
                        Modal.confirm({
                            title: Translate.t("message.confirm"),
                            content: Translate.t("message.confirmContentDelete", { name: Translate.t("camera.title") }),
                            icon: <ExclamationCircleFilled />,
                            okText: Translate.t("action.yes"),
                            cancelText: Translate.t("action.no"),
                            onOk() {
                                return new Promise(async (resolve, reject) => resolve(await handleRemove())).catch(() => alert(Translate.t("error.operation")))
                            },
                            onCancel() { },
                        })
                    }}
                >
                    {Translate.t("action.delete")}
                </Button>
            ),
        },
    ]


    const handleHiddenColumns = (collIndex) => {
        if (columnsVisives.length > 0)
            return columnsVisives.find((i, index) => index === collIndex && i.visible)
        return true
    }

    const onChangeHiddenColumns = (index, checked) => {
        let _columns = (columnsVisives || []).map((i, _index) => ({ ...i, visible: _index === index ? checked : i.visible }))
        setValueStorage("columnsCamera", JSON.stringify(_columns))
        setColumnsVisives(_columns)
    }

    useEffect(() => {
        let value = getValueStorage("columnsCamera")
        if (value) {
            setColumnsVisives(JSON.parse(value))
        } else {
            let _columns = [
                { label: Translate.t("common.name.title"), key: "0", visible: true },
                { label: Translate.t("common.mac.title"), key: "1", visible: true },
                { label: Translate.t("common.serialNumber.title"), key: "2", visible: true },
                { label: Translate.t("common.ip.title"), key: "3", visible: true },
                { label: Translate.t("common.location.title"), key: "4", visible: true },
                { label: Translate.t("common.lastConnection"), key: "5", visible: true },
                { label: Translate.t("common.username.title"), key: "6", visible: true },
                { label: Translate.t("common.password.title"), key: "7", visible: true },
                { label: Translate.t("common.enabled"), key: "8", visible: true },
                { label: Translate.t("common.creationDate"), key: "9", visible: true },
                { label: Translate.t("camera.model.title"), key: "10", visible: true },
                { label: Translate.t("common.id.title"), key: "11", visible: true },
            ]
            setValueStorage("columnsCamera", JSON.stringify(_columns))
            setColumnsVisives(_columns)
        }
    }, [])

    useEffect(() => {
        function handleClickOutside(event) {
            if (divMenuContext.current && !divMenuContext.current.contains(event.target)) {
                setContextMenuVisible(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [divMenuContext])


    return (
        <>
            <Row id="container-table" style={{ marginTop: "20px" }}>
                <Col xs={24} className="card_default padding-list">
                    <Row className="space-botton padding" >
                        <Col xs={12}>
                            <span className="text-header primary-text-color">{Translate.t("title.search_result")}</span>
                        </Col>
                        <Col xs={12} className="alng-rigth">
                            {columnsVisives.length > 0 && <Popover content={(columnsVisives || []).map((i, index) => <p value={i.label} key={i.key}>
                                <Checkbox checked={i.visible}
                                    onClick={(e) => {
                                        onChangeHiddenColumns(index, e.target.checked)
                                        e.stopPropagation()
                                    }}
                                >   <span className="secondary-text-color">{i.label}</span>
                                </Checkbox>
                            </p>)} placement="bottom" title={null} trigger="hover">
                                <span className="secondary-text-color"> {Translate.t("common.selectInfo")}</span>
                                <DownOutlined />
                            </Popover>}
                        </Col>
                    </Row >
                    <Table
                        scroll={{ y: 9999, x: 400 }}
                        size="small"
                        dataSource={data}
                        columns={columns.filter((column) => handleHiddenColumns(column.index))}
                        pagination={pagination}
                        loading={loading}
                        rowKey={(row) => row.id}
                        onChange={handleTableChange}
                        onRow={(record) => {
                            return {
                                onClick: (event) => handleOnClick(event, record),
                                onContextMenu: (event) => handleContextMenu(event, record)
                            }
                        }}
                    />
                    {contextMenuVisible && (
                        <ul ref={divMenuContext} className="popup" style={{ left: `${contextMenuPosition.x}px`, top: `${contextMenuPosition.y}px` }}>
                            {(items || []).map(i => <li className="secondary-text-color" key={i.key}>{i.item}</li>)}
                        </ul>
                    )}
                </Col>
            </Row>
            {open &&
                <Register
                    initialValues={item}
                    open={open}
                    setOpen={setOpen}
                    search={search}
                    locations={locations}
                    hosts={hosts}
                />}
        </>
    )
}

export default List